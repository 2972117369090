<template>
  <v-container class="container--fluid text-center">
    <v-card class="mt-5">
      <v-card-title class="">
        <span class="blue--text text-h6"> Santral Ekle </span>
      </v-card-title>
      <v-divider />

      <v-card-text>
        <div class="col-12 d-flex">
          <v-btn
            class="mt-2"
            outlined
            color="indigo"
            dense
            @click="createFarm"
          >
            Santral Ekle
          </v-btn>
        </div>

        <v-data-table
          :headers="headers"
          :items="desserts"
          :sort-by="['santral Adı', 'Kurulu Güç', 'Ekleme Tarihi', 'Stataus']"
          :items-per-page="itemPerPage"
          class="elevation-1"
          dense
        >
          <template v-slot:item.santralCode="{item}">
            <v-icon
              small
              class="mr-2"
              @click="editItemOpen(item)"
            >
              mdi-pencil-circle
            </v-icon>
            <v-icon
              small
              @click="deleteItem(item)"
            >
              mdi-delete-circle
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="dialog"
      persistent
      max-width="800px"
    >
      <v-card>
        <v-card-title>
          <span class="blue--text"> <h2>Santral Ekle</h2></span>
        </v-card-title>
        <v-card-text>
          <v-col cols="12 d-flex">
            <v-col cols="6">
              <!--Saha Adı:-->
              <v-text-field
                ref="farmName"
                v-model="farmdata.farmName"
                label="Saha Adı:"
                class="col-10 mb-0 mt-0 pt-0 pb-0"
                outlined
                dense
                disabled
                hidden-footer
                hidden-details
                hidden-hint
                required
              />

              <!--Kurulu Güç:-->
              <v-text-field
                ref="farmName"
                v-model="santraldata.NominalPlantPower.value"
                label="Kurulu Güç:"
                class="col-10 mb-0 mt-0 pt-0 pb-0"
                outlined
                dense
                hidden-footer
                hidden-details
                hidden-hint
                required
              />

              <!--Modül Sıcaklık Katsayısı:-->
              <v-text-field
                v-model="santraldata.ModuleTemperatureCoefficient.value"
                label="Modül Sıcaklık Katsayısı:"
                outlined
                dense
                class="col-10 mb-0 mt-0 pt-0 pb-0 mr-3"
                hidden-footer
                hidden-details
                hidden-hint
                required
              />

              <!--Durum-->
              <v-select
                v-model="santraldata.status"
                label="Durum:"
                dense
                class="col-10 ml-0 mb-0 mt-0 pt-0 pb-0"
                attach=""
                outlined
                :items="['Active', 'Passive']"
              />
            </v-col>
            <v-col cols="6">
              <!--Santral Adı:-->
              <v-text-field
                ref="farmName"
                v-model="santraldata.santral"
                label="Santral Adı:"
                class="col-10 mb-0 mt-0 pt-0 pb-0"
                outlined
                dense
                hidden-footer
                hidden-details
                hidden-hint
                required
              />

              <!--Kurulu Güç Birimi:-->
              <v-text-field
                v-model="santraldata.NominalPlantPower.unit"
                label="Kurulu Güç Birimi:"
                outlined
                dense
                class="col-10 ml-0 mb-0 mt-0 pt-0 pb-0"
                hidden-footer
                hidden-details
                hidden-hint
                required
              />

              <!--Modül Sıcaklık Katsayısı Birimi:-->
              <v-text-field
                v-model="santraldata.ModuleTemperatureCoefficient.unit"
                label="Modül Sıcaklık Katsayısı Birimi:"
                outlined
                dense
                class="col-10 ml-0 mb-0 mt-0 pt-0 pb-0"
                hidden-footer
                hidden-details
                hidden-hint
                required
              />
            </v-col>
          </v-col>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            dense
            outlined
            @click="dialog = false"
          >
            Kapat
          </v-btn>
          <v-btn
            v-if="farmEdit === 'false'"
            color="blue darken-1"
            text
            dense
            outlined
            @click="setfunc"
          >
            Kaydet
          </v-btn>

          <v-btn
            v-if="farmEdit === 'true'"
            color="blue darken-1"
            text
            dense
            outlined
            @click="editFarm"
          >
            Güncelle
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { getFarm } from '@/api/Request/farm';
import helper from '@/api/helper';
import { setSantralCreate, setSantralEdit, getSantralList } from '@/api/Request/santral';

export default {
  data: () => ({
    headers: [],
    desserts: [],
    itemPerPage: 10,
    dialog: false,
    search: '',
    valid: true,
    lazy: true,
    singleSelect: true,
    edit: false,
    editdialog: false,

    santraldata: {
      santral: '',
      farmName: '',
      timeInterval: '',
      status: 'Active',
      ModuleTemperatureCoefficient: {
        unit: '',
        value: '',
      },
      NominalPlantPower: {
        unit: '',
        value: '',
      },
    },

    farmEdit: 'false',
    companyCode: null,
    prefix: null,
    farmdata: {},
  }),
  watch: {},
  created() {
    this.selectedFarmFunc();
  },
  mounted() {},
  methods: {
    selectedFarmFunc() {
      const storage = window.localStorage;
      const user_company = JSON.parse(window.atob(storage.getItem('user_company')));
      this.companyCode = user_company.companyCode;
      this.prefix = user_company.prefix;
      this.getFarm();
      this.getSantralList();
    },
    // seçilen saha bilgisini alma
    getFarm() {
      const params = {
        condiniton: {
          companyCode: this.companyCode,
          prefix: this.prefix,
        },
      };

      getFarm(params).then((res) => {
        if (!helper.is_Empty(res.data.data)) {
          this.farmdata = res.data.data;
        }
      });
    },

    // sahaya baglı santral listesi
    getSantralList() {
      this.headers = [
        { text: 'Santral Adı', value: 'santral' },
        { text: 'Kurulu Güç', value: 'NominalPlantPower.value' },
        { text: 'Ekleme Tarihi', value: 'createTime' },
        { text: 'Status', value: 'status' },
        { text: '', value: 'santralCode' },
      ];

      const params1 = {
        condiniton: {
          prefix: this.prefix,
        },
      };

      getSantralList(params1).then((res) => {
        if (res.data.success === 'true') {
          this.desserts = res.data.data;
        }
      });
    },

    setfunc() {
 
      const selectedFarm = this.farmdata.filter((item) => {
        if (item.prefix === this.prefix) {
          return item;
        }
      });

      this.santraldata.farmName = selectedFarm[0].farmName;
      this.santraldata.companyCode = selectedFarm[0].companyCode;
      this.santraldata.companyName = selectedFarm[0].companyName;
      this.santraldata.prefix = selectedFarm[0].prefix;

      const params = {
        data: this.santraldata,
        condiniton: {
          companyCode: this.companyCode,
          prefix: this.prefix,
        },
      };

      setSantralCreate(params).then((res) => {
        // console.log(res);
        setTimeout(() => {
          this.selectedFarmFunc();
        }, 500);
      });
    },
    createFarm() {
      this.santraldata = {
        santral: '',
        farmName: this.farmdata.farmName,
        installedPower: '',
        timeInterval: '',
        status: 'Active',
        ModuleTemperatureCoefficient: {
          unit: '',
          value: '',
        },
        NominalPlantPower: {
          unit: '',
          value: '',
        },
      };

      this.dialog = true;
      this.farmEdit = 'false';
    },
    editItemOpen(item) {
      this.santraldata = item;
      this.santraldata.farmName = this.farmdata.farmName;
      this.santraldata = {
        santral: '',
        farmName: this.farmdata.farmName,

        timeInterval: '',
        status: 'Active',
        ModuleTemperatureCoefficient: {
          unit: '',
          value: '',
        },
        NominalPlantPower: {
          unit: '',
          value: '',
        },
      };
      this.santraldata = { ...this.santraldata, ...item };
      this.farmEdit = 'true';
      this.dialog = true;
    },

    editFarm() {
      this.santraldata.farmName = this.farmdata.farmName;
      this.santraldata.companyCode = this.companyCode;

      const params = {
        condiniton: {
          companyCode: this.companyCode,
          prefix: this.prefix,
          santralCode: this.santraldata.santralCode,
        },
        data: this.santraldata,
      };
 
      setSantralEdit(params).then((res) => {
        // console.log(res);
        this.selectedFarmFunc();
      });
    },
  },
};
</script>

<style>
#userForm .col.col-12 {
  padding: 0;
}
</style>
